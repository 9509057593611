main {
    max-width: 2000px;
    margin: auto;
}

.topMain {
    text-align: center;
    display: flex;
    position: relative;
    height: 60vmin;
    margin: auto;
}

.partX, .imgX{
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    margin: auto;
}

.imgX {
    pointer-events: none;
}


.exxo {
    width: min(60vw, 1500px);
}

.slogan {
    margin-top: 25px;
    width: min(70vw, 1300px);
}

main h1 {
    color: whitesmoke;
    font-weight: bold;
    font-size: 4.5rem;

}

main h2 {
    font-weight: bold;
    font-size: 3.5rem;
}

.qqMots, .technos {
    padding: 20px;
    margin: 40px 20px;
    border-radius: 15px;
    text-align: center;
    box-shadow: 4px 4px 3px RGBA(20,20,20,0.8);
}

.qqMots {
    background-color: RGBA(10,10,20,.93);
    backdrop-filter: blur(20px) saturate(150%);
}

.technos {
    background-color: RGBA(245,245,235,0.8);
    backdrop-filter: blur(2px) saturate(120%);

}

.qqMots h2, .qqMots .link {
    background: linear-gradient(100deg, #DA8383 5%, #E235BC 34%, #8641F5 60%, #00dfb7 100%);
}

.technos h2, .technos .link {
    background: linear-gradient(100deg, #fA3363 5%, #6e1aff 35%, #1252cf 70%, #1232ef 105%);
}

.qqMots h2, .qqMots .link, .technos h2, .technos .link {
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    position: relative;
    display: inline-flex;
}



.qqMots h2::before, .qqMots .link::before, .technos h2::before, .technos .link::before {
  content: "";
  position: absolute;
  top: 100%;
  width: 100%;
  left: 0;
  height: 3px;
  border-radius: 2px;
}

/*.qqMots h2::before, .qqMots a::before {*/
/*    background: linear-gradient(100deg, #DA8383 5%, #E235BC 34%, #8641F5 60%, #00dfb7 100%);*/
/*}*/

/*.technos h2::before, .technos a::before, .qqMots h2::before, .qqMots a::before  {*/
/*    background: linear-gradient(100deg, #fA3363 5%, #6e1aff 35%, #1252cf 70%, #1232ef 105%);*/
/*}*/

.qqMots p, .technos p {
    text-align: justify;
    font-size: 1.5rem;
    font-weight: bold;
}

.qqMots p {
     color: whitesmoke;

}

.technos p {
    color: #212121;
}

.qqMots .link, .technos .link{
    margin-left: 75%;
    font-size: 1.75rem;
    font-weight: bold;
}

.bb {
    display: flex;
    align-items: center;
}


.bb:nth-child(2n) {
    flex-direction: row-reverse;
}

@media screen and (max-width: 660px) {
    .bb {
        flex-wrap: wrap;
        flex-direction: column;
    }

    .bb:nth-child(n) {
        flex-direction: column-reverse;
        margin-bottom: 30px;
    }

    .qqMots h2, .technos h2 {
        font-size: 2.4rem;
    }

    .qqMots .link{
        margin-left: 65%;
    }

}

.bb img {
    width: 200px;
    height: auto;
    transition-duration: 0.6s;
    cursor: pointer;
}

.bb img:hover {
    filter: drop-shadow(7px 7px 4px #222);
}

.bb img:active {
    filter: initial;
}

.details {
    margin-top: 25px;
}

.chiffres {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

@media screen and (max-width:800px) {
    .chiffres {
    grid-template-columns: 1fr;
}
}

.subChiffres img {
    height: 160px;
    width: auto;
}

.subChiffres:nth-child(1) {
        color: #16a085;
}

.subChiffres:nth-child(2) {
        color: #e74c3c;
}

.subChiffres:nth-child(3) {
        color: #2980b9;
}

.subChiffres h4{
    font-size: 2.3rem;
    color: whitesmoke;
    margin: 20px 0;
}

.mapx {
    position: relative;
    margin-bottom: 50px;
}


.conTitre {
    display: inline-block;
    position: absolute;
    margin: auto;
    background: RGBA(230, 230, 230, 0.1);
    backdrop-filter: blur(3px) saturate(150%);
    z-index: 20;
    border-radius: 10px 10px 0 0;

}

.conTitre h2 {
    margin: 0;
}

.luximg {
    width: min(calc(100% - 40px),2000px);
    margin: auto;
    display: block;
    border-radius: 5%;
    padding: 0 10px;
}


iframe {
    border: 0;
    border-radius: 15px 15px 0 0;
    width: 100%;
    position: absolute;
    top: 0;
    left:0;
    height: 500px;
}

h3 {
    color: wheat;
    display: block;
}

.coord {
    margin-top: 500px;
}


