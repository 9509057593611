.main {
    height: 200px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    padding: 0 5%;
    column-gap: 5%;
    row-gap: 5%;
}

.subMain:nth-child(1), .subMain:nth-child(4) {
    box-shadow: 3px 3px 3px RGBA(20,20,20, 0.7);
    background-image: url("../../Media/Images/lux.jpg");
}

.subMain:nth-child(2), .subMain:nth-child(3)  {
    box-shadow: 3px 3px 3px RGBA(20,20,20, 0.7);
    background-color: whitesmoke;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 1.2rem;
    padding: 5px;
}
