.try {
    display: inline-block;
    font-size: 2rem;
    cursor: pointer;
    margin: 15px 0;
    padding: 10px 15px;
    color: RGBA(230, 230, 230);
    font-weight: bolder;
    border-radius: 50px;

    background: RGBA(255,255,255,0.2);
    backdrop-filter: blur(5px) saturate(150%);
    /*box-shadow: 5px 5px 1px RGB(20, 40, 80);;*/
    transition-duration: 0.7s;
    border: 1px solid RGBA(250,250,250, 0.3);
}

.try:hover {
    color: RGB(0, 43, 61);
}

.try:active {
    box-shadow: inset 2px 2px 10px RGBA(20,20,20,0.4);
}

@media screen and (orientation: portrait) {
    .try {
        margin-bottom: 15px;
    }
}


.screenDemo {
    display: grid;
    grid-template-columns: 1fr 55% 1fr;
    z-index: 2;
}

.videobg {
    display: flex;
    position: absolute;
    width: 100vw;
    /*height: 115vh;*/
    background-size: cover;
    z-index: 2;
    object-fit: cover;
    top: 0;
    left:0;
}

.demo{
    min-height: 92vh;
    display: flex;

}


.lArrow, .rArrow {
    padding: 15% 10px;
    display: grid;
    grid-template-rows: 1fr 2fr 1fr;
}

.iconex {
   transition-duration: 0.6s;
   color: whitesmoke;

}

.iconex:hover {
    color: rgb(70,160,200);
    text-shadow: 3px 3px 2px black;

}


.demo {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    justify-content: center;
}

@media screen and (max-width: 1024px) {
    .screenDemo {
        grid-template-columns: 1fr 5fr 1fr;
        transform: none;
    }

    .lArrow, .rArrow {
        padding: 15% 0;
    }
}

.lArrow div, .rArrow div {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: normal;
    font-size: 50px;
    cursor: pointer;
}


.cont img {
    position: absolute;
    width: 30vmax;
    transform: translateX(-100px) scaleX(-1);
    filter: invert(1) sepia(1) saturate(5);
    bottom: 0;
    left: 0;
    z-index: 0;

}


.screen {
    background-color: RGB(220, 220, 220);
    background-size: cover;
    width: 100%;
    border-radius: 15px;
    align-self: center;
    justify-self: center;
    color: RGB(0, 43, 61);
    box-shadow: 10px 10px 10px RGBA(20, 20, 20, 0.9);
}
