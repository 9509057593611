ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}


ul {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border-radius: 20px;
  min-width: 280px;
  justify-content: center;
}

li {
  background-color: rgba(255, 255, 255, 0.45);
  backdrop-filter: blur(20px) saturate(150%);
  border-radius: 15px;
  padding: 10px;
  overflow: hidden;
  margin: 5px;
  cursor: pointer;
  width: calc((100% - 90px) / 3 );
  display: inline-block;
  transition-duration: 0.6s;
  overflow-wrap: break-word;
  min-width: 260px;
}

li:hover {
  background-color: rgba(255, 255, 255, 0.85);
}



.avatar {
  color: #fe5a6f;
  font-size: 1.7rem;
  font-weight: bold;
}

.txtCord {
  margin-left: 10px;

}

.contCord {
  text-align: center !important;
  font-size: 1.4rem !important;
}


.row {
  width: 100%;
  height: 8px;
  background-color: #999;
  margin-top: 12px;
}
