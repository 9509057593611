.menu {
    width: 100%;
    height: 100%;
    background-color: RGBA(30,60,90,0.95);
    position: fixed;
    top: 0;
    left: 0;
    display: block;
    z-index: 8;
    transition-duration: 0.7s;
    overflow-y: hidden;
}

.sMenu {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-content: center;
    text-align: center;
    font-weight: bold;
    font-size: 2rem;
}

@media screen and (max-width: 900px) {
    .sMenu {
        font-size: initial;
    }
}

.sMenu ul {
    height: 100%;
    max-width: 90%;
    flex-direction: column;
    align-content: center;
    transition: transform 1s ease-in-out;
}

a {
    margin: 2vh;
}

.sMenu li {
    background: rgb(0,70,99);
    background: linear-gradient(110deg, rgba(13,110,135,1) 0%, rgba(6,80,115,1) 80%);
    color: whitesmoke;
    transition-duration: 1s;
    border: 5px Solid whitesmoke;
    box-shadow: 3px 3px 2px RGBA(20, 20, 20, 0.7);
    font-size: 2rem;
    margin: 0;
}

.sMenu li:hover {
    background: linear-gradient(110deg, rgba(33,125,150,1) 0%, rgba(6,80,125,1) 80%);
}

.sMenu li:active {
    box-shadow: none;
}

.drp {
    height: 90px;
    margin-top: 30px;
}

.drp img {
    width: 67px;
    border-radius: 50%;
    margin: 0 5px;
}

.drp img:hover {
    transform: rotateX(30deg);
    transform-style: preserve-3d;
    transform-origin: 50% 50%;
}
