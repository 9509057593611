.footer {
    height: 80px;
}

.subFooter {
    height: 60px;
    margin: 20px auto 0 auto;
    width: 90%;
    background-color: whitesmoke;
    box-shadow: 3px 3px 3px RGBA(20,20,20, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.3rem;
}

