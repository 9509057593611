.header {
    height: 80px;
    width: 90%;
    margin: auto;
}

.subHeader {
    height: 60px;
    margin: 10px auto 0 auto;
    background-color: whitesmoke;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    box-shadow: 3px 3px 3px RGBA(20,20,20, 0.7);
}

.subHeader div {
    align-self: center;
    justify-self: center;
    font-weight: bold;
    font-size: 2rem;
}