.priv {
    max-width: 1500px;
    margin: auto;
}

.priv h2 {
    color: whitesmoke;
    font-size: 3rem;
    padding: 20px;
    text-align: center;
    text-decoration: underline;
}

.priv p {
    color: whitesmoke;
    font-size: 1.8rem;
    font-weight: bold;
    padding: 20px;
    text-align: justify;
}

.ovp {
   height: 100vh;
   overflow-y: auto;
}

.ovp footer {
    position: fixed;
    bottom: 0;
}