.trx {
    align-items: center;
    height: auto;
    display: flex;
    touch-action:cross-slide-x;
}

.trx_button {
    margin: 0 10px;
    background-color: transparent;
    border: none;
    padding: 0;
    font-size: 6rem;
    font-weight: bold;
    color: whitesmoke;
    filter: drop-shadow(2px 2px 2px rgb(30 30 30 / 0.6));
    transition-duration: 0.3s;
}

.trx_button:hover {
    stroke: black;
    stroke-width: 20;
    stroke-linecap: round;
}

.trx_button:active {
    filter: none;
}

.trx_centre {
    padding: 20px 30px;
}

.trx_centre img {
    max-width: min(320px, 100%);
    height: auto;
    border-radius: 30px;
    box-shadow: 3px 3px 2px rgba(30, 30, 30, 0.6);
}

@media screen and (max-width: 750px) {

    .trx_button {
        display: none;
    }

    .trx_centre img {
        width: max(50%, 200px);
    }

    .trx_centre {
        padding: 0;
    }

    .trx_centre p {
        text-align: center;
    }


}

.trx_ext {
    display: block;
    flex-direction: column;
    align-items: center;
    width: 100%;
    position: absolute;
    height: 500px;
}

.trx_c {
    width: 100%;
    min-height: 500px;
    position: relative;
    overflow: hidden;
}