header {

   padding: 3rem;

   display:flex;
   flex-direction: row;
   flex-wrap: nowrap;
   align-content: center;
   justify-content: space-between;
   align-items: center;

   background-color: RGBA(20,20,20,0);

}

header img {
  height: 50px;
  width: auto;
  z-index: 10;
  cursor: pointer;
}

.navicon {
  width: 60px;
  height: 50px;
  position: relative;
  transition-duration: 0.6s;
  cursor: pointer;
  z-index: 10;
}

.navicon:hover span {
    box-shadow: 3px 3px 4px RGBA(20,20,30,0.7);
}

.navicon:active span {
    box-shadow: initial;
}

.navicon span {
  display: block;
  position: absolute;
  height: 8px;
  width: 100%;
  background: whitesmoke;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}

.navicon span {
  -webkit-transform-origin: left center;
  transform-origin: left center;
}

.navicon span:nth-child(1) {
  top: 0;
}

.navicon span:nth-child(2) {
  top: 21px;
}

.navicon span:nth-child(3) {
  top: 42px;
}

@media screen and (orientation: portrait) and (max-width: 600px){
    header img {
        height: 36px;
        width: auto;
    }

    .navicon {
        width: 42px;
        height: 36px;
    }

    .navicon span {
      height: 6px;
    }

    .navicon span:nth-child(1) {
      top: 0;
    }

    .navicon span:nth-child(2) {
      top: 15px;
    }

    .navicon span:nth-child(3) {
      top: 30px;
    }

}