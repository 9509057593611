.top {
    height: 30px;
    background-color: RGB(20,30,60);
    border-radius: 10px 10px 0 0 ;
}

.dot {
  height: 15px;
  width: 15px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  margin: 7px 7px 0 5px;
}

.dot1 {
      background-color: indianred;
}

.dot2 {
      background-color: RGB(250,190,30) ;
}

.dot3 {
      background-color: springgreen;
}