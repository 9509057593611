footer {
    background-color: RGB(0,43,61);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    text-align: center;
    color: RGB(250,240,225);
    flex-wrap: wrap;
    column-gap: 40px;
    padding: 50px 0;
    font-weight: bold;
}

footer div {
    min-width: 360px;
    margin: 10px;
}

a {
    color: whitesmoke;
    text-decoration: none;
    cursor: pointer;
    transition-duration: 1s;
}
