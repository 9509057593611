html, body {
   background-image: url("../Media/Images/bg.webp");
   background-repeat: no-repeat;
   background-attachment: fixed;
   background-size: cover;
   background-color: #222222;
   overflow: hidden;
   height: 100vh;
   width: 100vw;
}


.app {
   backdrop-filter: blur(200px) saturate(250%);
   background-color: RGBA(50,50,50,0.3);
   white-space: pre-line;
}

#root {
  white-space: pre-line;
}

.ov {
   overflow-y: scroll;
   height: 100vh;
}