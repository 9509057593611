.main {
    height: 200px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    padding: 0 5%;
    column-gap: 5%;
    row-gap: 5%;
}

.subMain:nth-child(1) {
    box-shadow: 3px 3px 3px RGBA(20,20,20, 0.7);
    background-image: url("../../Media/Images/lux.jpg");
    grid-row: 1/-1;
}

.subMain:nth-child(2) {
    box-shadow: 3px 3px 3px RGBA(20,20,20, 0.7);
    background-color: whitesmoke;
}

.subMain:nth-child(3) {
    box-shadow: 3px 3px 3px RGBA(20,20,20, 0.7);
    background-color: whitesmoke;
}