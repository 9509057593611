.brxCont {
    position: absolute;
    width: min(94%,1500px);
    max-height: 92vh;
    top: 3%;
    left: 0;
    right: 0;
    margin: auto;
    color: black;
    border: 3px solid whitesmoke;
    backdrop-filter: blur(10px) saturate(150%);
    box-shadow: 3px 3px 2px RGBA(30,30,30,0.7);
    border-radius: 20px;
    font-size: 1.4rem;
    font-weight: bold;
    text-align: justify;
    z-index: 1000;
    overflow-y: auto;
    box-sizing: border-box;
    display: flex;
}

.brxSubCont {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 15px;
    box-sizing: border-box;
}

.brxCont img:nth-of-type(1) {
    width: 40%;
    display: block;
    margin: auto;
    max-width: 320px;
    height: auto;
}

.brxCont img:nth-of-type(2) {
    width: 60%;
    max-width: 520px;
    height: auto;
    display: block;
    margin: auto;
    border-radius: 10px;
    filter: drop-shadow(2px 2px 0 whitesmoke) drop-shadow(-2px -2px 0 whitesmoke)
    drop-shadow(2px -2px 0 whitesmoke) drop-shadow(-2px 2px 0 whitesmoke);
}

.brxCont a {
    text-decoration: underline;
    color: #1252cf;
    text-align: center;
    margin: 20px auto 0 auto;
    display: table;
    transition-duration: 0.6s;
}

.brxCont a:hover {
    color: #e74c3c;
}

.naviconx {
  width: 60px;
  height: 50px;
  position: absolute;
  cursor: pointer;
  z-index: 10;
  top: 20px;
  right: 0;
}


.naviconx span {
  display: block;
  position: absolute;
  height: 8px;
  width: 100%;
  background: RGBA(30,30,60,0.9);
  border-radius: 9px;
  right: 10px;
  top: 10px;
}


.naviconx span:nth-child(1) {
  top: 0;
}

.naviconx span:nth-child(2) {
  top: 42px;
}